<template>
  <el-dialog
    title="房源状态设置"
    :visible.sync="showDialog"
    width="500px"
    :before-close="clearWindow"
  >
    <el-form
      label-position="right"
      label-width="100px"
    >
      <el-form-item
        label="状态设置:"
      >
        <el-radio-group v-model="closeType">
          <el-radio :label="1">
            关闭房源
          </el-radio>
          <el-radio :label="2">
            开启房源
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="选择方式:"
      >
        <el-radio-group
          v-model="timeType"
          @change="changeTimeType"
        >
          <el-radio :label="1">
            按起止日期
          </el-radio>
          <el-radio :label="2">
            按日选择
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="timeType==1"
        label="日期:"
      >
        <el-date-picker
          v-model="checkDays"
          style="width: 95%"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        v-show="timeType==2"
        label="日期:"
      >
        <el-date-picker
          v-model="checkDays"
          type="dates"
          placeholder="选择日期"
          size="small"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: '',
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    setHomeType: {
      type: String,
      default: 'all'
    },
    goodsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      closeType: 1, // 关闭房源还是开启房源1、关闭；2、开启
      timeType: 1, // 时间选择方式1、时间段；2、指定时间；
      checkDays: '',
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    changeTimeType(e) {
      this.checkDays = '';
    },
    clearWindow() {
      this.$emit('update:showDialog', false);
      this.closeType = 1; // 关闭房源还是开启房源1、关闭；2、开启
      this.timeType = 1; // 时间选择方式1、时间段；2、指定时间；
      this.checkDays = '';
    },
    onSubmit() {
      console.log(this.checkDays)
      if (!this.checkDays || this.checkDays.length === 0) {
        this.$message.warning('请选择时间');
        return
      }
      let params, api;
      params = {
        supplierId: JSON.parse(localStorage.getItem('userInfo')).suppliersId,
        status: 0,
        goodsIds: [],
        specValueList: []
      }
      if (this.closeType === 1) {
        api = this.$api.goodsmanage.trade_goods_batchCloseHouse;
      } else if (this.closeType === 2) {
        api = this.$api.goodsmanage.trade_goods_batchOpenHouse;
      }
      if (this.setHomeType === 'some') {
        params.goodsIds = this.goodsList.map(item => item.id);
      } else if (this.setHomeType === 'all') {
        params.goodsIds = [];
      }
      if (this.timeType === 1) {
        params.specValueList = this.getDays(this.checkDays[0], this.checkDays[1]);
      } else if (this.timeType === 2) {
        params.specValueList = this.checkDays.map(item => dayjs(item).format('YYYY-MM-DD'));
      }
      this.$confirm('是否确认修改房源?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(api, params).then(row => {
          if (row.code === 0) {
            this.$message.success('操作成功！');
            this.clearWindow();
          }
        })
      }).catch(() => {});
    },
    // 获取两天之间的所有天数
    getDays(minDate, maxDate) {
      let startDate = new Date(minDate);
      let endDate = new Date(maxDate);
      let newDay = [];
      while (endDate.getTime() >= startDate.getTime()) {
        let year = startDate.getFullYear();
        let month = startDate.getMonth() + 1;
        let day = startDate.getDate();
        // 加一天
        startDate.setDate(day + 1);
        if (month.toString().length === 1) {
          month = '0' + month;
        }
        if (day.toString().length === 1) {
          day = '0' + day;
        }
        newDay.push(year + '-' + month + '-' + day);
      }
      return newDay;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
