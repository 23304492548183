/*
  属性列表
*/
const STOP_STATUS = [{
  value: '1',
  label: '启用',
},
{
  value: '2',
  label: '禁用',
}];
/*
    属性类型
  */
const ATTR_TYPE = [{
  value: '1',
  label: '基础属性',
},
{
  value: '2',
  label: '描述属性',
},
{
  value: '3',
  label: '规格属性',
}];
/*
    表单维护方式
  */

const CAT_RANK = [{
  value: '1',
  label: '一级类目',
}, {
  value: '2',
  label: '二级类目',
}, {
  value: '3',
  label: '三级类目',
}];

// 类目
const CAT_STATUS = [{
  value: '1',
  label: '上架',
}, {
  value: '2',
  label: '下架',
}];
// 商品审核状态
const GOODS_AUDIT_STATUS = [
  {
    value: '1',
    label: '未提审',
  },
  {
    value: '2',
    label: '审核中',
  },
  {
    value: '3',
    label: '审核成功',
  },
  {
    value: '4',
    label: '审核失败',
  },
  {
    value: '5',
    label: '已经下架',
  },
];
/**
 * 商品类型
 */
const GOODS_BUY_TYPE_MAP = {
  0: '普通卖品',
  1: '组合卖品',
  2: '普通非卖品',
  3: '组合非卖品',
};

const TAG_TYPE = [
  { value: 1, label: '用户标签' }, { value: 2, label: '商家标签' }, { value: 3, label: '运营标签' },
];
const TAG_USE = [
  { value: 1, label: '用户偏好' }, { value: 2, label: '用户评价' }, { value: 3, label: '商品标签' }, { value: 4, label: '服务承诺' }, { value: 5, label: '商品审核' }, { value: 6, label: '商家管理' },
];
const GOODS_TYPE = [
  { value: 1, label: '农产品' }, { value: 2, label: '名宿' }, { value: 3, label: '旅游' }, { value: 3, label: '餐饮' },
];

export {
  STOP_STATUS,
  ATTR_TYPE,
  CAT_RANK,
  CAT_STATUS,
  GOODS_AUDIT_STATUS,
  TAG_TYPE,
  TAG_USE,
  GOODS_TYPE,
  GOODS_BUY_TYPE_MAP,
};
