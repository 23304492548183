var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择商品类型",
        visible: _setup.props.show,
        width: "440px",
        "before-close": _setup.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_setup.props, "show", $event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wd-center" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _setup.add("0")
                },
              },
            },
            [_vm._v(" 普通卖品 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _setup.add("1")
                },
              },
            },
            [_vm._v(" 组合卖品 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function ($event) {
                  return _setup.add("2")
                },
              },
            },
            [_vm._v(" 普通非卖品 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "small" },
              on: {
                click: function ($event) {
                  return _setup.add("3")
                },
              },
            },
            [_vm._v(" 组合非卖品 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }