<template>
  <div>
    <div class="app-container">
      <div class="outer">
        <search-panel
          flex
          show-btn
          :is-entry="false"
          @getList="getListChange"
        >
          <div>
            <p>商品ID</p>
            <el-input
              v-model.trim="id"
              clearable
              :maxlength="9"
              size="mini"
              placeholder="商品ID"
              @input="id=id.replace(/[^\d]/g,'')"
            />
          </div>
          <div>
            <p>商品类目</p>
            <ChangeCat
              :value="catList"
              @change="handleChange"
            />
          </div>
          <div>
            <p>区域馆</p>
            <ChangeSite @change="changeSite" />
          </div>
          <!-- <div>
            <p>特色馆</p>
            <SearchAreaSpecial @change="(e) => brandSiteId = e" />
          </div> -->
          <div>
            <p>是否批发商品</p>
            <el-select
              v-model="isWholesale"
              clearable
              size="mini"
              placeholder="请选择"
            >
              <el-option
                label="否"
                value="0"
              />
              <el-option
                label="是"
                value="1"
              />
            </el-select>
          </div>
          <div>
            <p>商品状态</p>
            <el-select
              v-model="status"
              clearable
              size="mini"
              placeholder="请选择商品状态"
            >
              <el-option
                label="未提审"
                :value="0"
              />
              <el-option
                label="审核中"
                :value="1"
              />
              <el-option
                label="审核通过"
                :value="2"
              />
              <el-option
                label="不通过"
                :value="3"
              />
            </el-select>
          </div>
          <div>
            <p>上下架状态</p>
            <el-select
              v-model="isOnSale"
              clearable
              size="mini"
              placeholder="请选择商品状态"
            >
              <el-option
                label="上架"
                value="1"
              />
              <el-option
                label="下架"
                value="0"
              />
            </el-select>
          </div>
          <div>
            <p>商品名称</p>
            <el-input
              v-model.trim="name"
              clearable
              size="mini"
              placeholder="商品名称"
            />
          </div>
          <div>
            <p>创建时间</p>
            <el-date-picker
              v-model="timeData"
              size="mini"
              type="datetimerange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            />
          </div>
          <div>
            <p>商品类型</p>
            <el-select
              v-model="goodsTypes"
              multiple
              clearable
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="key in Object.keys(GOODS_BUY_TYPE_MAP)"
                :key="key"
                :label="GOODS_BUY_TYPE_MAP[key]"
                :value="key"
              />
            </el-select>
          </div>
          <div>
            <p>库存预警</p>
            <el-select
              v-model="isQuantityWarning"
              clearable
              size="mini"
              placeholder="请选择"
            >
              <el-option
                label="是"
                value="1"
              />
              <el-option
                label="否"
                value="2"
              />
            </el-select>
          </div>
          <el-button
            slot="button-right"
            size="mini"
            type="primary"
            @click="operation('add')"
          >
            新增商品
          </el-button>
          <el-button
            slot="button-right"
            size="mini"
            type="primary"
            @click="operation('editSomeHome')"
          >
            批量修改房源
          </el-button>
        </search-panel>

        <el-table
          v-loading="loading"
          class="table-container"
          :data="tableData"
          border
          style="width: 100%"
          :max-height="tabMaxHeight"
          highlight-current-row
          :header-cell-style="{
            background: '#D9DDE1',
            color: '#0D0202',
            'font-weight': 400,
            fontSize: '12px'
          }"
          row-key="id"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            reserve-selection
            :selectable="checkboxT"
            width="55"
          />
          <el-table-column
            prop="id"
            label="商品ID"
            width="80"
          />
          <el-table-column
            prop="name"
            label="商品名称"
            min-width="250"
          />
          <el-table-column
            prop="name"
            label="商品封面图"
            min-width="81"
          >
            <template slot-scope="{row}">
              <el-image
                v-if="row.coverImgUrl"
                style="width: 60px; min-height: 60px"
                :src="row.coverImgUrl.split(',')[0]"
                :preview-src-list="row.coverImgUrl.split(',')"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="backGoryName"
            label="商品类目"
            min-width="200"
          />
          <el-table-column
            label="商品类型"
            min-width="96"
          >
            <template #default="{row}">
              <span :class="[[2,3].includes(+row.goodsType) && 'green']">
                {{ GOODS_BUY_TYPE_MAP[row.goodsType] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sellPrice"
            label="商品价格"
          />
          <el-table-column
            prop="stockQuantity"
            label="库存"
          />
          <el-table-column
            prop="salesSumTrue"
            label="销量"
          />
          <el-table-column
            prop="totalCommentStar"
            label="评分"
          />
          <el-table-column
            label="售卖状态"
          >
            <template slot-scope="{row}">
              <span :class="[getSaleState(row).color]">{{ getSaleState(row).text }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="售卖时间"
            width="200"
          >
            <template slot-scope="{row}">
              <div style="white-space: pre-line;">
                {{ setSaleTime(row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="审核状态"
            width="110px"
          >
            <template slot-scope="{row}">
              <span :class="[getAuditStatus(row).color]">
                {{ getAuditStatus(row).text }}
                <el-popover
                  v-if="getAuditStatus(row).reason"
                  placement="top-start"
                  title="拒绝理由"
                  width="400"
                  trigger="hover"
                  :content="getAuditStatus(row).reason"
                >
                  <i
                    slot="reference"
                    class="el-icon-warning"
                  ></i>
                </el-popover>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="上下架状态"
            width="120"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isOnSale"
                :disabled="scope.row.status === 2 ? false : true"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="
                  (e) => {
                    changeSwitch(e, scope.row, 'isOnSale');
                  }
                "
              />
              &nbsp;
              <span>{{ scope.row.isOnSale == 1 ? "上架" : "下架" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否开启自提"
            width="120"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.ancestryCategoryId != 2">
                <el-switch
                  v-model="scope.row.isTemplateSelf"
                  :disabled="scope.row.status === 2 ? false : true"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="
                    (e) => {
                      changeSwitch(e, scope.row, 'isTemplateSelf');
                    }
                  "
                />
                &nbsp;
                <span>{{ scope.row.isTemplateSelf == 1 ? "开启" : "关闭" }}</span>
              </div>
              <div v-if="scope.row.ancestryCategoryId == 2">
                不支持
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="是否置顶"
            width="120"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isRecommended"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="
                  (e) => {
                    changeTop(e, scope.row);
                  }
                "
              />
              &nbsp;
              <span>{{ scope.row.isRecommended == 1 ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="参加活动"
            width="110px"
          >
           <template #default="{ row }">
            
              <template v-if="Array.isArray(row.pageWithPromDTOs) && row.pageWithPromDTOs.length > 0 ">
                <div v-for="item of (row.pageWithPromDTOs ?? [])" class="promIds-container">
                  {{ item.promId }}
                </div>
              </template>
              <template v-else>
                <div>
                  未参加
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="200"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.createTime?setTime(scope.row.createTime):'' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="最近上架时间"
            width="200"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.onTime?setTime(scope.row.onTime):'' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="180"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="operation('show', scope.row)"
              >
                查看
              </el-button>
              <!--编辑禁用 :disabled="scope.row.status == 1 || scope.row.isOnSale == 1" -->
              <el-button
                :disabled="scope.row.status == 1"
                type="text"
                size="mini"
                @click="operation('edit', scope.row)"
              >
                编辑
              </el-button>
              <el-button
                v-if="![2,9].includes(+scope.row.ancestryCategoryId)"
                type="text"
                size="mini"
                @click="operation('addStock', scope.row)"
              >
                增加库存
              </el-button>
              <el-button
                v-if="scope.row.ancestryCategoryId == 2"
                type="text"
                size="mini"
                @click="operation('homeSet', scope.row)"
              >
                房源管理
              </el-button>
              <el-button
                v-if="[9].includes(+scope.row.ancestryCategoryId)"
                :disabled="scope.row.isOnSale === 1"
                type="text"
                size="mini"
                @click="operation('ticketSkuSet', scope.row)"
              >
                规格管理
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="operation('copy', scope.row)"
              >
                复制
              </el-button>
              <el-button
                :disabled="scope.row.status == 1 || scope.row.isOnSale == 1"
                type="text"
                size="mini"
                :style="{color: scope.row.status == 1 || scope.row.isOnSale == 1 ? '' : 'red'}"
                @click="operation('delete', scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData && tableData.length > 0"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagination.nowPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <ChangeStore
      :show-change-store="showChangeStore"
      url="/nb/goods/add"
      @closeBox="showChangeStore = false"
    />

    <!-- 添加库存 -->
    <AddStock
      :show-add-stock.sync="showAddStock"
      :goods-id.sync="goodsId"
      @onSuccess="getList()"
    />

    <!-- 设置房源状态 -->
    <set-state-dialog
      :show-dialog.sync="showSetHomeState"
      :set-home-type="setHomeType"
      :goods-list="checkGoodsList"
    />

    <!-- 选择商品类型 -->
    <SelectGoodsType
      :show="showSelectType"
      @clear="showSelectType = false"
      @submit="selectGoodsType"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import qs from 'qs';
import {
  STOP_STATUS, CAT_RANK, GOODS_AUDIT_STATUS, GOODS_BUY_TYPE_MAP,
} from '../data.js';
import ChangeStore from '@/components/common/changeStore.vue';
import ChangeCat from '@/components/common/changeCat.vue';
import ChangeSite from '@/components/common/changeSite.vue';
import AddStock from './components/addStock.vue';
import SetStateDialog from './homeSet/set-state-dialog.vue';
import { projectConfigDetail } from '@/utils/projectConfig';
// import SearchAreaSpecial from '@/components/common/SearchAreaSpecial/index.vue';
import SelectGoodsType from './components/SelectGoodsType.vue';

export default {
  name: 'attr-list',
  components: {
    ChangeStore,
    ChangeCat,
    ChangeSite,
    AddStock,
    SetStateDialog,
    // SearchAreaSpecial,
    SelectGoodsType,
  },
  data() {
    return {
      isLimit: 0, // 是否被禁用了
      catList: [],
      stopStatus: STOP_STATUS,
      catRank: CAT_RANK,
      goodsAuditStatus: GOODS_AUDIT_STATUS,
      GOODS_BUY_TYPE_MAP,
      // 筛选
      id: '',
      backendCategoryId: '', // 选中类目
      goodsCatId: 1, // 农产品类目id
      status: '',
      name: '',
      startTime: '',
      endTime: '',
      timeData: [],
      tagName: '',
      storeId: '',

      // 表格
      tableData: [],
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      loading: true,
      showChangeStore: false,
      isOnSale: '', // 上下架
      siteId: '',
      // brandSiteId: '',
      supplierName: '', // 店铺名称
      level: '', // 市县馆等级
      isWholesale: '', // 是否批发

      showAddStock: false, // 添加库存弹窗
      goodsId: 0, // 选中商品id
      // 设置房源状态
      showSetHomeState: false,
      setHomeType: 'some',
      checkGoodsList: [], // 选中商品

      suppliersId: JSON.parse(localStorage.getItem('userInfo')).suppliersId, // 供应商id

      tabMaxHeight: null, // 表格最大高度
      isQuantityWarning: '',
      goodsTypes: [], // 商品购买类型
      showSelectType: false,
    };
  },
  watch: {
    timeData(item) {
      if (item) {
        this.startTime = Date.parse(new Date(item[0]));
        this.endTime = Date.parse(new Date(item[1]));
      } else {
        this.startTime = null;
        this.endTime = null;
      }
    },
  },
  created() {
    let userInfo = localStorage.getItem('userInfo');
    this.isLimit = userInfo && JSON.parse(userInfo).isLimit ? userInfo && JSON.parse(userInfo).isLimit : 0;
    this.storeId = userInfo && JSON.parse(userInfo).storeList && JSON.parse(userInfo).storeList[0] ? JSON.parse(userInfo).storeList[0].storeId : localStorage.getItem('defaultStoreId');
    this.goodsCatId = projectConfigDetail.goodsCatId;
  },
  activated() {
    this.getList();
  },
  mounted() {
    setTimeout(() => {
      this.catList = [1, 10];
    }, 1000);
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    // 类目
    handleChange(value) {
      if (value && value.length > 0) {
        this.backendCategoryId = value[value.length - 1];
      } else {
        this.backendCategoryId = '';
      }
    },
    getListChange() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios
        .get(this.$api.goodsmanage.trade_goods_page, {
          params: {
            currentPage: this.pagination.currentPage,
            pageSize: this.pagination.nowPageSize,
            id: this.id,
            backendCategoryId: this.backendCategoryId,
            status: this.status,
            name: this.name,
            startTime: this.startTime ? this.startTime : undefined,
            endTime: this.endTime ? this.endTime : undefined,
            storeId: this.storeId,
            isOnSale: this.isOnSale ? this.isOnSale : undefined,
            siteId: this.siteId ? this.siteId : undefined,
            level: this.level ? this.level : undefined,
            isWholesale: this.isWholesale,
            // tagName: this.tagName,
            isQuantityWarning: this.isQuantityWarning,
            // brandSiteId: this.brandSiteId,
            goodsTypes: this.goodsTypes && this.goodsTypes.join(','),
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data.records;
            this.pagination.count = Number(res.data.total);
          } else {
            this.tableData = [];
          }
          this.loading = false;
        });
    },
    async changeTop(e, row) {
      try {
        await this.$confirm(e === 1 ? '是否确认置顶商品?' : '是否确认取消置顶', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        const res = await this.$axios.post(
          this.$api.goodsmanage.trade_goods_recommended,
          qs.stringify({
            status: e,
            id: Number(row.id),
          }),
        );
        if (res.code !== 0) {
          row.isRecommended = e === 1 ? 0 : 1;
          return;
        }
        row.isRecommended = e;
        this.$message({
          message: '操作成功！',
          type: 'success',
        });
      } catch (err) {
        row.isRecommended = e === 1 ? 0 : 1;
      }
    },
    changeSwitch(e, row, name) {
      console.log(e);
      if (this.isLimit === 1) {
        this.$message({ type: 'error', message: '您当前暂无权限，详情请联系客服' });
        row.isOnSale = e === 1 ? 0 : 1;
        return;
      }
      let title;
      let api;
      let params;
      if (name === 'isTemplateSelf') {
        title = ['是否确认开启自提?', '是否确认关闭自提?'];
        api = this.$api.goodsmanage.trade_goods_self_status;
        params = {
          name: '',
          status: e,
          templateId: 0,
          templateType: 3,
          goodsId: row.id,
        };
      } else {
        title = ['是否确认上架商品?', row.isExchange === 1 ? '该商品已参加了加价换购活动，确认下架?' : '是否确认下架商品?'];
        api = this.$api.goodsmanage.trade_goods_modify;
        params = qs.stringify({
          status: e,
          id: Number(row.id),
        });
      }
      this.$confirm(e === 1 ? title[0] : title[1], '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post(api, params)
            .then((res) => {
              if (res.code === 0) {
                row[name] = e;
                this.$message({
                  message: '操作成功！',
                  type: 'success',
                });
              } else {
                row[name] = e === 1 ? 0 : 1;
                if (name === 'isTemplateSelf') {
                  this.$confirm(`${res.msg}，点击确认去添加自提模板`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  }).then(() => {
                    window.open(`${window.location.origin}/nb/goods/freight?freightType=3`);
                  }).catch(() => {
                  });
                }
              }
            });
        })
        .catch(() => {
          row[name] = e === 1 ? 0 : 1;
        });
    },
    selectGoodsType(type) {
      const path = '/nb/goods/add';
      const query = {
        storeId: this.storeId,
        suppliersId: this.suppliersId,
        goodsBuyType: type,
      };
      if (type === '1' || type === '3') {
        // 组合商品
        const obj = {
          activeSteps: '1',
          goodsType: '1',
          backendCategoryId: this.goodsCatId,
          type: 'add',
        };
        Object.assign(query, obj);
      }
      this.$router.push({
        path,
        query,
      });
    },
    // 操作
    operation(type, row) {
      let detailUrl;
      if (type === 'add') {
        if (+this.isLimit === 1) {
          this.$message({ type: 'error', message: '您当前暂无权限，详情请联系客服' });
          return;
        }
        this.showSelectType = true;
      } else if (type === 'edit' || type === 'show') {
        if (type === 'edit' && this.isLimit == 1) {
          this.$message({ type: 'error', message: '您当前暂无权限，详情请联系客服' });
          return;
        }
        detailUrl = '/nb/goods/detail';
        this.$router.push({
          path: detailUrl,
          query: {
            type,
            goodsType: row.ancestryCategoryId,
            goodsId: row.id,
            storeId: this.storeId,
            suppliersId: this.suppliersId,
            goodsBuyType: row.goodsType,
            backendCategoryId: row.backendCategoryId,
          },
        });
      } else if (type === 'delete') {
        let msg = '此操作将删除商品, 是否继续?';
        let msgAss = '该商品规格已被组合成组合商品规格，删除商品可能导致组合商品失效，确认删除？';
        let msgEx = '该商品已参加了加价换购活动，删除商品可能导致活动中的原规格失效，确认删除？';
        let msgAll = '该商品已参加了加价换购活动,且被组合成组合商品规格，删除商品可能导致活动中的原规格和组合商品失效，确认删除？';
        if (row.assemblyNum > 0 && row.isExchange == 1) {
          msg = msgAll;
        } else if (row.assemblyNum > 0) {
          msg = msgAss;
        } else if (row.isExchange == 1) {
          msg = msgEx;
        }
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$axios
              .post(this.$api.goodsmanage.trade_goods_remove, qs.stringify({
                id: Number(row.id),
              }))
              .then((res) => {
                if (res.code === 0) {
                  this.$message({
                    message: '操作成功！',
                    type: 'success',
                  });
                  this.getList();
                }
              });
          }).catch(() => {});
      } else if (type === 'homeSet') {
        if (this.isLimit == 1) {
          this.$message({ type: 'error', message: '您当前暂无权限，详情请联系客服' });
          return;
        }
        detailUrl = '/nb/goods/homeSet';
        this.$router.push({
          path: detailUrl,
          query: {
            goodsId: row.id,
            goodsBuyType: row.goodsType,
          },
        });
      } else if (type === 'addStock') {
        this.goodsId = Number(row.id);
        this.showAddStock = true;
      } else if (type === 'editSomeHome') {
        // 修改指定房源
        if (this.checkGoodsList.length === 0) {
          this.$message.warning('请勾选酒店民宿商品后再试');
          return;
        }
        this.setHomeType = 'some';
        this.showSetHomeState = true;
      } else if (type === 'editAllHome') {
        // 修改所有房源
        this.setHomeType = 'all';
        this.showSetHomeState = true;
      } else if (type === 'ticketSkuSet') {
        detailUrl = '/nb/goods/pickCardSet';
        this.$router.push({
          path: detailUrl,
          query: {
            goodsId: row.id,
            goodsType: '9',
            backendCategoryId: row.backendCategoryId,
          },
        });
      } else if (type === 'copy') {
        this.$confirm('是否确认复制商品？').then(async () => {
          const { code } = await this.$axios.get(this.$api.goodsmanage.trade_goods_copyGoods, { params: { goodsId: row.id } });
          if (code !== 0) return;
          this.$message.success('复制成功');
          this.getList();
        });
      }
    },
    setTime(time) {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
    // 筛选地区
    changeSite(value) {
      this.siteId = value[value.length - 1];
      this.level = value.length;
      this.getList();
    },
    setSaleTime({ saleTime, saleStatus }) {
      if (!saleTime) return '-';
      saleTime = JSON.parse(saleTime);
      if (saleStatus === 1) {
        const str = saleTime.rangeDay.replace(',', ' 至\n');
        return str;
      } if (saleStatus === 2) {
        const str = `${saleTime.rangeDay.replace(',', ' 至 ')}\n每天${saleTime.rangeTime.replace(',', ' - ')}`;
        return str;
      }
      return '-';
    },
    // 设置表格高度
    setTableHeight() {
      let tableRect = document.getElementsByClassName('table-container')[0].getBoundingClientRect();
      this.$nextTick(() => {
        this.tabMaxHeight = window.innerHeight - tableRect.top - 95;
        console.log(this.tabMaxHeight);
      });
    },
    // 禁用指定勾选
    checkboxT(row, index) {
      // 审核通过的民宿商品可勾选
      return row.ancestryCategoryId === '2' && row.status === 2;
    },
    handleSelectionChange(e) {
      this.checkGoodsList = e;
    },
    getSaleState(row) {
      if (+row.isOnSale === 1) {
        if (!row.goodsSellDto || +row.goodsSellDto.sellStatus === 0) return { text: '售卖中', color: 'green' };
        if (+row.goodsSellDto.sellStatus === 1) return { text: '暂停售卖' };
        if (+row.goodsSellDto.sellStatus === 2) return { text: '已停售' };
      }
      return { text: '已停售' };
    },
    getAuditStatus(row) {
      if (+row.status === 0) return { text: '未提审', color: 'red' };
      if (+row.status === 1) return { text: '审核中' };
      if (+row.status === 2) return { text: '审核通过', color: 'green' };
      if (+row.status === 3) return { text: '不通过', color: 'red', reason: row.goodsDetail };
      return {};
    },
  },
};
</script>

<style scoped lang="scss">
.promIds-container {
  margin-bottom: 10px;
  &:first-child {
    margin-bottom: 0;
  }
}
.goods-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
</style>
