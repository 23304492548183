<template>
  <el-dialog
    title="添加库存"
    :visible.sync="showAddStock"
    width="650px"
    :before-close="handleClose"
    @open="getSkuList"
  >
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="imageIds"
          label="规格图片"
        >
          <template slot-scope="{row}">
            <img
              style="width: 50px;height: 50px;"
              :src="row.imageIds"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="specValue"
          label="规格值"
        />
        <el-table-column
          prop="stockQuantity"
          label="剩余库存"
        >
          <template slot="header">
            剩余库存
            <el-tooltip
              class="item"
              effect="dark"
              content="当剩余库存小于等于预警预存时，剩余库存显示红色"
              placement="top"
            >
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </template>
          <template #default="{row}">
            <span :style="row.stockQuantity<=row.stockQuantityWarning?'color: red':''">{{ row.stockQuantity }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="添加库存数"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            添加库存数
            <el-tooltip
              class="item"
              effect="dark"
              content="注：商品库存 = 剩余库存 + 添加库存数"
              placement="top"
            >
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <el-input-number
              v-model="row.addStockQuantity"
              :min="0"
              :max="999999"
              label="添加库存数量"
              size="small"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="handleClose"
      >取 消</el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    showAddStock: {
      type: Boolean,
      default: false,
    },
    goodsId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {},
  watch: {

  },
  created() {},
  mounted() {

  },
  methods: {
    onSubmit() {
      for (let item of this.tableData) {
        if (item.addStockQuantity === undefined) {
          this.$message.error('添加库存数不能为空');
          return;
        }
      }
      this.$confirm('是否确认提交？').then(() => {
        this.$axios
          .post(this.$api.goodsmanage.add_goods_stock, {
            addStockQuantityDtoList: this.tableData,
          })
          .then((res) => {
            if (res.code === 0) {
            // this.showAddStock = false;
              this.handleClose();
              this.$message.success('添加库存成功');
              this.$emit('onSuccess');
            }
          });
      });
    },
    getSkuList() {
      this.$axios
        .get(this.$api.goodsmanage.sku_list, {
          params: { goodsId: this.goodsId },
        })
        .then((res) => {
          if (res.code === 0) {
            this.tableData = res.data;
          }
        });
    },
    handleClose() {
      this.$emit('update:showAddStock', false);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
