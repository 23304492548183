<template>
  <el-cascader
    v-model="catList"
    :options="levelArr"
    :props="levelOptions"
    clearable
    size="mini"
    @change="handleLevelChange"
    @visible-change="visibleChange"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    parentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      catList: [],
      levelArr: [], // 类目下拉列表数据
      levelOptions: {
        checkStrictly: true,
        label: 'name',
        value: 'id',
        lazy: true,
        lazyLoad: this.lazyLevelLoad,
      },
      defaultCatList: [],
    };
  },
  watch: {
    value(item) {
      console.log(item);
      this.defaultCatList = item;
    },
  },
  methods: {
    visibleChange(e) {
      if (this.levelArr.length === 0 && e) {
        this.getCategoryList();
      }
    },
    handleLevelChange(value) {
      this.$emit('change', value);
    },
    getCategoryList() {
      let that = this;
      that.$axios.get(
        that.$api.backendCategory.backend_category_list,
        {
          params: {
            currentPage: 1,
            pageSize: 50,
            parentId: this.parentId,
          },
        },
      ).then((res) => {
        if (res.code === 0) {
          const list = _.get(res.data, 'records', []);
          that.levelArr = that.levelArr.concat(list);
        }
      });
    },
    lazyLevelLoad(node, resolve) {
      let that = this;
      const { level, value } = node;
      console.log(node, 'node');
      if (level > 0 && value) {
        that.$axios({
          method: 'get',
          url: that.$api.backendCategory.backend_category_list,
          params: {
            currentPage: 1,
            pageSize: 50,
            parentId: value,
          },
        }).then((res) => {
          if (res.code === 0 && res.data.records.length > 0) {
            res.data.records.forEach((item) => {
              // item.leaf = level >= 1
            });
            resolve(res.data.records);
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    },
  },
};
</script>

<style>

</style>
