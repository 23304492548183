<template>
  <el-cascader
    v-model="pavilion"
    :options="pavilionOptions"
    :props="siteOptions"
    size="mini"
    clearable
    placeholder="请选择展馆"
    @change="pavilionChange"
    @visible-change="visibleChange"
  />
</template>

<script>
export default {
  props: {},
  data() {
    return {
      pavilion: '', // 展馆
      pavilionOptions: [], // 展馆选项
      siteOptions: {
        checkStrictly: true,
        label: 'siteName',
        value: 'siteId',
      },
    };
  },
  mounted() {
    this.getSiteInfo();
  },
  methods: {
    pavilionChange(e) {
      this.$emit('change', e);
    },
    visibleChange(e) {
      // if (this.pavilionOptions.length === 0 && e) {
      //   this.getSiteInfo();
      // }
    },
    // 获取展馆列表信息
    getSiteInfo() {
      let that = this;
      that.$axios.get(that.$api.promApply.all_site_list, {
        params: {
          siteType: 1,
        },
      }).then((res) => {
        if (res.code === 0) {
          let str = JSON.stringify(res.data);
          str = str.replaceAll('[]', '""');
          that.pavilionOptions = JSON.parse(str);
        }
      });
    },
  },
};
</script>

<style lang="scss">

</style>
