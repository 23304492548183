<script setup>
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits('clear', 'submit');

function add(type) {
  emit('submit', type);
  emit('clear');
}
function handleClose() {
  emit('clear');
}
</script>

<template>
  <el-dialog
    title="选择商品类型"
    :visible.sync="props.show"
    width="440px"
    :before-close="handleClose"
  >
    <div class="wd-center">
      <el-button
        type="primary"
        size="small"
        @click="add('0')"
      >
        普通卖品
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="add('1')"
      >
        组合卖品
      </el-button>
      <el-button
        type="success"
        size="small"
        @click="add('2')"
      >
        普通非卖品
      </el-button>
      <el-button
        type="success"
        size="small"
        @click="add('3')"
      >
        组合非卖品
      </el-button>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">

</style>
