var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加库存",
        visible: _vm.showAddStock,
        width: "650px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showAddStock = $event
        },
        open: _vm.getSkuList,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "imageIds", label: "规格图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("img", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: { src: row.imageIds, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "specValue", label: "规格值" },
              }),
              _c(
                "el-table-column",
                {
                  attrs: { prop: "stockQuantity", label: "剩余库存" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              style:
                                row.stockQuantity <= row.stockQuantityWarning
                                  ? "color: red"
                                  : "",
                            },
                            [_vm._v(_vm._s(row.stockQuantity))]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _vm._v(" 剩余库存 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "当剩余库存小于等于预警预存时，剩余库存显示红色",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "添加库存数" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _vm._v(" 添加库存数 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "注：商品库存 = 剩余库存 + 添加库存数",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-warning-outline" })]
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input-number", {
                          attrs: {
                            min: 0,
                            max: 999999,
                            label: "添加库存数量",
                            size: "small",
                          },
                          model: {
                            value: row.addStockQuantity,
                            callback: function ($$v) {
                              _vm.$set(row, "addStockQuantity", $$v)
                            },
                            expression: "row.addStockQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }