var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-container" }, [
        _c(
          "div",
          { staticClass: "outer" },
          [
            _c(
              "search-panel",
              {
                attrs: { flex: "", "show-btn": "", "is-entry": false },
                on: { getList: _vm.getListChange },
              },
              [
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品ID")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        maxlength: 9,
                        size: "mini",
                        placeholder: "商品ID",
                      },
                      on: {
                        input: function ($event) {
                          _vm.id = _vm.id.replace(/[^\d]/g, "")
                        },
                      },
                      model: {
                        value: _vm.id,
                        callback: function ($$v) {
                          _vm.id = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品类目")]),
                    _c("ChangeCat", {
                      attrs: { value: _vm.catList },
                      on: { change: _vm.handleChange },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("区域馆")]),
                    _c("ChangeSite", { on: { change: _vm.changeSite } }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("是否批发商品")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.isWholesale,
                          callback: function ($$v) {
                            _vm.isWholesale = $$v
                          },
                          expression: "isWholesale",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "否", value: "0" } }),
                        _c("el-option", { attrs: { label: "是", value: "1" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品状态")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请选择商品状态",
                        },
                        model: {
                          value: _vm.status,
                          callback: function ($$v) {
                            _vm.status = $$v
                          },
                          expression: "status",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "未提审", value: 0 },
                        }),
                        _c("el-option", {
                          attrs: { label: "审核中", value: 1 },
                        }),
                        _c("el-option", {
                          attrs: { label: "审核通过", value: 2 },
                        }),
                        _c("el-option", {
                          attrs: { label: "不通过", value: 3 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("上下架状态")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请选择商品状态",
                        },
                        model: {
                          value: _vm.isOnSale,
                          callback: function ($$v) {
                            _vm.isOnSale = $$v
                          },
                          expression: "isOnSale",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "上架", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "下架", value: "0" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品名称")]),
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "商品名称",
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("创建时间")]),
                    _c("el-date-picker", {
                      attrs: {
                        size: "mini",
                        type: "datetimerange",
                        align: "right",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "default-time": ["00:00:00", "23:59:59"],
                      },
                      model: {
                        value: _vm.timeData,
                        callback: function ($$v) {
                          _vm.timeData = $$v
                        },
                        expression: "timeData",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("商品类型")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          multiple: "",
                          clearable: "",
                          size: "mini",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.goodsTypes,
                          callback: function ($$v) {
                            _vm.goodsTypes = $$v
                          },
                          expression: "goodsTypes",
                        },
                      },
                      _vm._l(
                        Object.keys(_vm.GOODS_BUY_TYPE_MAP),
                        function (key) {
                          return _c("el-option", {
                            key: key,
                            attrs: {
                              label: _vm.GOODS_BUY_TYPE_MAP[key],
                              value: key,
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", [_vm._v("库存预警")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.isQuantityWarning,
                          callback: function ($$v) {
                            _vm.isQuantityWarning = $$v
                          },
                          expression: "isQuantityWarning",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "是", value: "1" } }),
                        _c("el-option", { attrs: { label: "否", value: "2" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "button-right",
                      size: "mini",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.operation("add")
                      },
                    },
                    slot: "button-right",
                  },
                  [_vm._v(" 新增商品 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      slot: "button-right",
                      size: "mini",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.operation("editSomeHome")
                      },
                    },
                    slot: "button-right",
                  },
                  [_vm._v(" 批量修改房源 ")]
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "table-container",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  "max-height": _vm.tabMaxHeight,
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#D9DDE1",
                    color: "#0D0202",
                    "font-weight": 400,
                    fontSize: "12px",
                  },
                  "row-key": "id",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    "reserve-selection": "",
                    selectable: _vm.checkboxT,
                    width: "55",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "id", label: "商品ID", width: "80" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "商品名称",
                    "min-width": "250",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "商品封面图",
                    "min-width": "81",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.coverImgUrl
                            ? _c("el-image", {
                                staticStyle: {
                                  width: "60px",
                                  "min-height": "60px",
                                },
                                attrs: {
                                  src: row.coverImgUrl.split(",")[0],
                                  "preview-src-list":
                                    row.coverImgUrl.split(","),
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "backGoryName",
                    label: "商品类目",
                    "min-width": "200",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "商品类型", "min-width": "96" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              class: [
                                [2, 3].includes(+row.goodsType) && "green",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.GOODS_BUY_TYPE_MAP[row.goodsType]
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "sellPrice", label: "商品价格" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "stockQuantity", label: "库存" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "salesSumTrue", label: "销量" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "totalCommentStar", label: "评分" },
                }),
                _c("el-table-column", {
                  attrs: { label: "售卖状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", { class: [_vm.getSaleState(row).color] }, [
                            _vm._v(_vm._s(_vm.getSaleState(row).text)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "售卖时间", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { "white-space": "pre-line" } },
                            [_vm._v(" " + _vm._s(_vm.setSaleTime(row)) + " ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "审核状态", width: "110px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            { class: [_vm.getAuditStatus(row).color] },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.getAuditStatus(row).text) + " "
                              ),
                              _vm.getAuditStatus(row).reason
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        title: "拒绝理由",
                                        width: "400",
                                        trigger: "hover",
                                        content: _vm.getAuditStatus(row).reason,
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "上下架状态", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              disabled: scope.row.status === 2 ? false : true,
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            on: {
                              change: (e) => {
                                _vm.changeSwitch(e, scope.row, "isOnSale")
                              },
                            },
                            model: {
                              value: scope.row.isOnSale,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isOnSale", $$v)
                              },
                              expression: "scope.row.isOnSale",
                            },
                          }),
                          _vm._v("   "),
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.isOnSale == 1 ? "上架" : "下架")
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "是否开启自提", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.ancestryCategoryId != 2
                            ? _c(
                                "div",
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      disabled:
                                        scope.row.status === 2 ? false : true,
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                    },
                                    on: {
                                      change: (e) => {
                                        _vm.changeSwitch(
                                          e,
                                          scope.row,
                                          "isTemplateSelf"
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.isTemplateSelf,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "isTemplateSelf",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.isTemplateSelf",
                                    },
                                  }),
                                  _vm._v("   "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.isTemplateSelf == 1
                                          ? "开启"
                                          : "关闭"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          scope.row.ancestryCategoryId == 2
                            ? _c("div", [_vm._v(" 不支持 ")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "是否置顶", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            on: {
                              change: (e) => {
                                _vm.changeTop(e, scope.row)
                              },
                            },
                            model: {
                              value: scope.row.isRecommended,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isRecommended", $$v)
                              },
                              expression: "scope.row.isRecommended",
                            },
                          }),
                          _vm._v("   "),
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.isRecommended == 1 ? "是" : "否")
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "参加活动", width: "110px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          Array.isArray(row.pageWithPromDTOs) &&
                          row.pageWithPromDTOs.length > 0
                            ? _vm._l(
                                row.pageWithPromDTOs ?? [],
                                function (item) {
                                  return _c(
                                    "div",
                                    { staticClass: "promIds-container" },
                                    [_vm._v(" " + _vm._s(item.promId) + " ")]
                                  )
                                }
                              )
                            : [_c("div", [_vm._v(" 未参加 ")])],
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "创建时间", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.createTime
                                    ? _vm.setTime(scope.row.createTime)
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "最近上架时间", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.onTime
                                    ? _vm.setTime(scope.row.onTime)
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", fixed: "right", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.operation("show", scope.row)
                                },
                              },
                            },
                            [_vm._v(" 查看 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: scope.row.status == 1,
                                type: "text",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.operation("edit", scope.row)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          ![2, 9].includes(+scope.row.ancestryCategoryId)
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.operation(
                                        "addStock",
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 增加库存 ")]
                              )
                            : _vm._e(),
                          scope.row.ancestryCategoryId == 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.operation("homeSet", scope.row)
                                    },
                                  },
                                },
                                [_vm._v(" 房源管理 ")]
                              )
                            : _vm._e(),
                          [9].includes(+scope.row.ancestryCategoryId)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: scope.row.isOnSale === 1,
                                    type: "text",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.operation(
                                        "ticketSkuSet",
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 规格管理 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.operation("copy", scope.row)
                                },
                              },
                            },
                            [_vm._v(" 复制 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              style: {
                                color:
                                  scope.row.status == 1 ||
                                  scope.row.isOnSale == 1
                                    ? ""
                                    : "red",
                              },
                              attrs: {
                                disabled:
                                  scope.row.status == 1 ||
                                  scope.row.isOnSale == 1,
                                type: "text",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.operation("delete", scope.row)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm.tableData && _vm.tableData.length > 0
              ? _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.pagination.currentPage,
                    "page-sizes": [10, 20, 30, 50],
                    "page-size": _vm.pagination.nowPageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.pagination.count,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("ChangeStore", {
        attrs: {
          "show-change-store": _vm.showChangeStore,
          url: "/nb/goods/add",
        },
        on: {
          closeBox: function ($event) {
            _vm.showChangeStore = false
          },
        },
      }),
      _c("AddStock", {
        attrs: { "show-add-stock": _vm.showAddStock, "goods-id": _vm.goodsId },
        on: {
          "update:showAddStock": function ($event) {
            _vm.showAddStock = $event
          },
          "update:show-add-stock": function ($event) {
            _vm.showAddStock = $event
          },
          "update:goodsId": function ($event) {
            _vm.goodsId = $event
          },
          "update:goods-id": function ($event) {
            _vm.goodsId = $event
          },
          onSuccess: function ($event) {
            return _vm.getList()
          },
        },
      }),
      _c("set-state-dialog", {
        attrs: {
          "show-dialog": _vm.showSetHomeState,
          "set-home-type": _vm.setHomeType,
          "goods-list": _vm.checkGoodsList,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.showSetHomeState = $event
          },
          "update:show-dialog": function ($event) {
            _vm.showSetHomeState = $event
          },
        },
      }),
      _c("SelectGoodsType", {
        attrs: { show: _vm.showSelectType },
        on: {
          clear: function ($event) {
            _vm.showSelectType = false
          },
          submit: _vm.selectGoodsType,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }